<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <h1 style="display: none" v-if="partnerDetail">
      {{ partnerDetail.name + ' - Official Partner Rentfix' }}
    </h1>
    <div class="container official--partner" v-if="partnerDetail">
      <official-partner-header :partnerDetail="partnerDetail"></official-partner-header>
      <div class="official--partner-content">
        <area-list :areas="areaList" :areaMeta="areaMeta" :partnerDetail="partnerDetail" />
      </div>
    </div>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
    <script v-html="jsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
const OfficialPartnerHeader = () => import('@/components/official-partner/header');
const AreaList = () => import('@/components/official-partner/area-list');
import DefaultLayout from '@/layouts/default';
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  components: {
    DefaultLayout,
    OfficialPartnerHeader,
    AreaList,
  },
  async fetch({ store, params, route, error }) {
    try {
      await store.dispatch('v2/officialPartner/getPartnerDetail', { url: params.partnerUrl });
    } catch (e) {
      if (e.message === 'Invalid') return;
      error(new Error(e.message), 404);
    }

    const baseUrl = store.state.global.baseUrl;
    const partnerDetail = store.state.v2.officialPartner.partnerDetail;

    if (partnerDetail) {
      await store.dispatch('v2/officialPartner/getAreaList', {
        listingType: 'R',
        partnerUuid: partnerDetail.uuid,
      });

      const regex = /(<([^>]+)>)/gi;
      const partnerName = partnerDetail.name;
      const partnerDesc = partnerDetail.description
        ? partnerDetail.description.replace(regex, '')
        : '';
      const ogImage = partnerDetail.logo_photo;

      let title = partnerName + ' | Official Partner Rentfix';

      let description = 'Rentfix.com - ' + partnerName + ' | ' + partnerDesc;
      if (description.length > 310) description = description.substring(0, 307) + '...';
      description += ' | Rentfix';

      const head = {
        title: title,
        meta: [
          { hid: 'og-url', property: 'og:url', content: baseUrl + route.fullPath },
          { hid: 'og-type', property: 'og:type', content: 'article' },
          {
            hid: 'og-title',
            property: 'og:title',
            content: title,
          },
          {
            hid: 'og-description',
            property: 'og:description',
            content: description,
          },
          { hid: 'og-site-name', property: 'og:site_name', content: 'Rentfix' },
          {
            hid: 'og-image',
            property: 'og:image',
            content: ogImage,
          },
          { hid: 'og-image-width', property: 'og:image:width', content: '450' },
          { hid: 'og-image-height', property: 'og:image:height', content: '450' },
          { hid: 'description', name: 'description', content: description },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    } else {
      store.commit('head/RESTORE_DEFAULT_VALUE', route);
    }
  },
  computed: {
    ...mapState({
      partnerDetail: (state) => state.v2.officialPartner.partnerDetail,
      areaList: (state) => state.v2.officialPartner.areaList,
      areaMeta: (state) => state.v2.officialPartner.areaMeta,
    }),
    partnerName() {
      return this.partnerDetail ? this.partnerDetail.name : '';
    },
    breadcrumbs() {
      if (!this.partnerDetail) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.partnerName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
    jsonldBreadcrumb() {
      let baseUrl = this.$store.state.global.baseUrl;
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('breadcrumb.homepage'),
            item: baseUrl + '/',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: this.partnerName,
            item: baseUrl + this.$router.currentRoute.path,
          },
        ],
      };
    },
    jsonld() {
      let baseUrl = this.$store.state.global.baseUrl;
      let path = this.$router.currentRoute.path;
      const regex = /(<([^>]+)>)/gi;
      const partnerName = this.partnerDetail ? this.partnerDetail.name : '';
      const partnerDesc =
        this.partnerDetail && this.partnerDetail.description
          ? this.partnerDetail.description.replace(regex, '')
          : '';

      let title = partnerName + ' | Official Partner Rentfix';

      let description = partnerName + ' | ' + partnerDesc;

      let areaList = [],
        i = 1;
      for (let area of this.areaList) {
        areaList.push({
          '@type': 'ListItem',
          position: i,
          item: this.getAreaJsonld({
            baseUrl: baseUrl,
            path: area.url,
            area: area,
            galleries: area.photos,
          }),
        });
        i++;
      }

      return {
        '@type': 'RealEstateListing',
        description: description,
        name: title,
        url: baseUrl + path,
        mainEntity: {
          '@type': 'ItemList',
          itemListElement: areaList,
        },
      };
    },
  },
  methods: {},
  mounted() {},
};
</script>
